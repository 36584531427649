"use client";
import React from "react";
import { FaSearch } from "react-icons/fa";
import styles from "./Navbar.module.css";
import Link from "next/link";
import Image from "next/image";
import { useUser } from "@hooks/Auth/useUser";
import Cookies from "js-cookie";
import { GenerateReceipt, getAllEvents } from "@services/eventAPI";
import { useEffect, useState } from "react";
import CountryList from "@components/CountryList/CountryList";
import LoginDropdown from "@components/LoginDropdown/LoginDropdown";
import { BiUser } from "react-icons/bi";
import { Spinner } from "@components/Spinner/Spinner";
import NewSearchBar from "@layout/CustomSearch/NewSearch";
import { isMobile } from "react-device-detect";
import JSZip from "jszip";
// import createPDF from "../app/utils/common/pdf";
import { saveAs } from "file-saver";
import { useRouter } from "next/router";
import { sendGAEvent } from "@next/third-parties/google";
import { trackGAEvent } from "@lib/gtag";
// import useAdjust from "@hooks/useAdjust";
// import { trackAdjustEvent } from "@utils/adjust";
// import Adjust from "@adjustcom/adjust-web-sdk";

const Navbar = ({ onSearchIconClick, allEvents = [], utm_source="", eventID="" }) => {
  const { user, tokens } = useUser();
  // // console.log({ user });
  // // console.log({ tokens });

  const fName = user?.fname;
  // // console.log({ allEvents });
  const [uniqueEventCountries, setUniqueEventCountries] = useState([]);
  const [loginLoading, setLoginLoading] = useState(false);
  useEffect(() => {
    if (allEvents.length > 0) {
      const eventCountries = allEvents.map((event) => event.eventCountry);
      const uniqueEventCountriesSet = Array.from(new Set(eventCountries));
      setUniqueEventCountries(uniqueEventCountriesSet);
    }
  }, [allEvents]);
  // // console.log(uniqueEventCountries);
  const selectedCountry = (Cookies.get("selectedCountry") || "").toLowerCase();
  const capitalizedCountry =
    selectedCountry.substring(0, 1).toUpperCase() +
    selectedCountry.substring(1);

  const isServer = typeof window === "undefined";
  // const { trackEvent } = useAdjust("3plp4ofteam8");

  // useEffect(() => {
  //   trackEvent("page_view");
  // }, []);

  // const handleButtonClick = () => {
  //   Adjust.trackEvent({
  //     eventToken: "YOUR_EVENT_TOKEN", // Replace with your event token
  //   });
  // };

  // // console.log({ uniqueEventCountries });

  return (
    <nav className={styles.navbar}>
      <div className={styles.left}>
        <Link
          href={isServer ? "/" : `/${capitalizedCountry}`}
          className={styles.logo}
          prefetch={false}
        >
          <div className={styles.overlayContainer}>
            <Image
              src="/assets/images/logo.png"
              alt="Tick'it Logo"
              width={65}
              height={65}
              priority={true}
            />
          </div>
        </Link>
        {/* <button
          onClick={() => {
            trackGAEvent("testadsa", "tesasdt", "tedasst");
            // trackAdjustEvent("aef8ch", { key: "test1", value: "event1" });
            Adjust.trackEvent({
              eventToken: "aef8ch", // Replace with your event token
            });
          }}
        >
          try
        </button> */}
        {allEvents.length > 0 && (
          <>
            {isMobile ? (
              <div className={styles.searchContainer}>
                <FaSearch
                  className={styles.searchIcon}
                  onClick={onSearchIconClick}
                />
              </div>
            ) : (
              <NewSearchBar allEvents={allEvents} />
            )}

            {/* <div>
              <CountryList list={uniqueEventCountries} />
            </div> */}
          </>
        )}
      </div>
      <div className={styles.right}>
        <div>
          <Link href="/organizer" prefetch={false}>
            <button className={`${styles.loginButton}`}>Organizer</button>
          </Link>
        </div>
        <div className={styles.buttonContainer}>
          <div>
            {!user || Object.keys(user).length === 0 ? (
              <Link
                href={eventID && eventID !== ""?  `/login?eventID=${eventID}`:"/login"}
                prefetch={false}
                onClick={() => {
                  setLoginLoading(true);
                }}
              >
                <button className={styles.loginButton_1}>
                  {loginLoading ? <Spinner /> : <BiUser />}
                </button>
              </Link>
            ) : (
              <LoginDropdown user={user} />
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
