import { useState } from "react";
import { Listbox } from "@headlessui/react";
import styles from "./CountryList.module.css";
import { usePathname, useRouter } from "next/navigation";
import Cookies from "js-cookie";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { countries } from "./countries";

const CountryList = ({ list }) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  let founds = {};
  const router = useRouter();
  const flag = countries;
  const pathname = usePathname();
  const getDisplayName = (country) => {
    const found = flag.find((obj) => {
      // console.log(country, obj.name);
      return obj.name.toLowerCase() === country.toLowerCase();
    });
    founds = found;
   
    
    if (country.toLowerCase() === "united arab emirates") {
      return `UAE`;
    }
    if (country === "United Kingdom") {
      return `UK`;
    }

    if (country === "United States") {
      return `USA`;
    }
    //as
    return `${country}`;
  };

  const [isOpen, setIsOpen] = useState(false);

  const countryInSelection = () => {
    let result = "United Arab Emirates";
    if (
      getDisplayName(decodeURIComponent(pathname.substring(1))).includes("/")
    ) {
      result = (
        <>
          <p>UAE</p>{" "}
          <img src="https://twemoji.maxcdn.com/2/svg/1f1f1-1f1e7.svg" alt="" />
        </>
      );
    } else {
      result =
        getDisplayName(decodeURIComponent(pathname.substring(1))).length >=
        2 ? (
          getDisplayName(decodeURIComponent(pathname.substring(1)))
        ) : (
          <div style={{ display: "flex", gap: "8px" }}>
            <p className={styles.display}>UAE</p>
            <img
              className={styles.flag}
              src="https://twemoji.maxcdn.com/2/svg/1f1f1-1f1e7.svg"
              alt=""
            />
          </div>
        );
    }
    return result;
  };

  return (
    <div className={styles.relative}>
      <Listbox
        value={selectedCountry}
        onChange={(country) => {
          setSelectedCountry(country);
          router.push(`/${encodeURIComponent(country)}`);
          Cookies.set("selectedCountry", decodeURIComponent(country)); // Set country in a cookie
          setIsOpen(false); // Close the dropdown
        }}
      >
        <Listbox.Button
          className={styles.countryName}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div style={{ display: "flex", gap: "8px" }}>
            <p className={styles.display}>{countryInSelection()}</p>
            {founds && <img src={founds.flag} className={styles.flag} alt="" />}
          </div>
          {isOpen ? (
            <FiChevronUp
              className={`${styles.arrow} ${isOpen ? styles.open : ""}`}
            />
          ) : (
            <FiChevronDown
              className={`${styles.arrow} ${isOpen ? "" : styles.open}`}
            />
          )}
        </Listbox.Button>

        <Listbox.Options
          className={
            isOpen ? `${styles.list} ${styles.open}` : `${styles.list}`
          }
        >
          {list.map((country, index) => (
            <Listbox.Option
              key={index}
              value={country.countryName}
              className={`${styles.countryNameList} ${
                selectedCountry === country.countryName ? styles.selected : ""
              }`}
            >
              <p>{getDisplayName(country.countryName)}</p>
              {founds && (
                <img src={founds.flag} className={styles.flags} alt="" />
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};

export default CountryList;
